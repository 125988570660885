@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.text-roboto {
  font-family: "Roboto";
  font-style: normal;
}
.text-inter {
  font-family: "Inter";
  font-style: normal;
}
.text-montserrat {
  font-family: "Montserrat";
  font-style: normal;
}

.text-grey {
  color: #a9a9a9;
}

/* KONKOIN */

.slick-prev:before {
  content: '←';
  margin-left: 5px;
}
.slick-prev {
  content: '←';
  /* margin-left: -30px; */
  z-index: 99 ;
}
.slick-next:before {
  content: '←';
  margin-left: -25px;
  z-index: -99;
}
@media screen and (min-width: 540px) {
  .slick-prev:before, .slick-next:before  {
    font-family: 'slick';
    font-size: 10px !important;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.slick-prev:before, .slick-next:before  {
  font-family: 'slick';
  font-size: 40px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bg-light-gray {
  background-color: #fbfbfb;
}
.bg-koni-dark {
  background-color: #080808;
}
.bg-koni-secondary {
  background-color: #252525;
}
.text-koni-danger {
  color: #d91f2d;
}
.bg-koni-danger {
  background-color: #d91f2d;
}

.roadmap {
  background-color: #252525;
}
.roadmap:hover {
  background-color: #d91f2d;
}

.text-bottom-navbar {
  color: #82858b;
}
.text-bottom-navbar:hover {
  color: #d91f2d;
}

.navbar-1 {
  background-color: transparant;
  width: 100vw;
}
.navbar-2 {
  background-color: rgb(37, 37, 37, 0.9);
  width: 100vw;
}

.news-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  
}
/* --------------------------- font size --------------------- */
.font-7 {
  font-size: 7px;
}
.font-8 {
  font-size: 8px;
}
.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-23 {
  font-size: 23px;
}
.font-24 {
  font-size: 24px;
}
.font-25 {
  font-size: 25px;
}
.font-26 {
  font-size: 26px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}

.font-40 {
  font-size: 40px;
}

/* -------------------------------- font - color ------------------------- */
.font-primary {
  color: #1b6aea;
}

.font-red {
  color: #f4465e;
}

.font-dark {
  color: #4c5058;
}

.font-green {
  color: #20cb6f;
}

.font-yellow {
  color: #f4b946;
}

.font-grey-dark {
  color: #4c5058;
}
.font-input-column {
  color: #c7c8da;
}

.font-blue-grey {
  color: #0c6db5;
}

.font-grey2 {
  color: #6c7487;
}
.font-modal-withdrawal-confirm {
  color: #6c7487;
}
/* ----------------------------------- background-color --------------------- */
.bg-blue {
  background-color: #1b6aea;
}

.bg-warning-custom {
  background-color: #fffade;
}

.bg-red-transparant {
  background-color: #fef1f2;
}

.bg-blue-transparant {
  background-color: #ecf9ff;
}

.bg-green-transparant {
  background-color: #e8ffe8;
}
.bg-yellow-transparant {
  background-color: #fcf4dd;
}
.bg-trade {
  background-color: #fafafc;
}
/* ----------------------------------- background-color improvement--------------------- */

.bg-imp-blue {
  background-color: #266de0;
}
.bg-imp-dark-blue {
  background-color: #1252b8;
}
.bg-imp-muted {
  background-color: #dbd7d7;
}
.bg-imp-muted2 {
  background-color: #fbfbfb;
}
.bg-imp-muted-purple {
  background-color: #f9f5ff;
}

.bg-imp-green {
  background-color: #c3fbdd;
}

.bg-dark-mode {
  background-color: #2f343e !important;
}

.bg-dark-mode2 {
  background-color: #282a32 !important;
}

.text-imp-muted {
  color: #dbd7d7;
}

.text-blue {
  color: #266de0;
}
.text-purple {
  color: #6941c6;
}
/* -------------------------- style ---------------------------------------- */
.search-wallet::placeholder {
  font-size: 14px;
  color: black;
}

.fixed-navbar {
  position: fixed;
  max-width: 15cm;
  bottom: 0;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 5px; */
  z-index: 3;
}

.fixed-button {
  position: fixed;
  bottom: 0;
}

.accordion-button:focus {
  box-shadow: none;
  background-color: white;
}

.login-form::placeholder {
  font-size: 15px;
  color: #808080;
}

.badge-sign-up {
  border-radius: 10px;
  font-weight: 400;
}

.center-page {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.center-page-order-history {
  position: absolute;
  top: 70%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.list-table-bg {
  background-color: #ffeded;
}

.search-form::placeholder {
  font-size: 13px;
  color: black;
}
.voucher-form::placeholder {
  color: #dde4ea;
  font-size: 12px;
}
.profile-circle {
  height: 70px;
  width: 70px;
  background-color: #1b6aea;
  border-radius: 50%;
  display: inline-block;
}

.form-change-password::placeholder {
  color: #dde4ea;
  font-size: 16px;
}

.delete-account-textarea::placeholder {
  font-size: 14px;
}
.pin-circle {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  display: inline-block;
}
.center-page-acttive-pin {
  position: absolute;
  top: 40%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.kyc-form::placeholder {
  color: #c7c8da;
  font: 14px;
}

.kyc-select-form {
  color: #c7c8da;
  font: 14px;
}

.kyc-select option:not([value=""]) {
  color: black;
}

.center-page-success-kyc {
  position: absolute;
  top: 40%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.vertikal-line {
  border-left: 1px solid #dee2e6;
  height: 25px;
}

.authenticator2FAForm::placeholder {
  font-size: 12px;
  color: #c7c8da;
}
.PhoneInput {
  /* background-color: rebeccapurple; */
  border: none;
  padding: 0px 5px 0px 5px;
}
.PhoneInputInput {
  height: 38px;
  border: none;
}

.phone-input {
  border: 1px solid #c3c8cc;
  border-radius: 4px;
}
.inputStyle {
  background: #f3f3f3;
  border-radius: 8px;
  width: 45px !important;
  height: 45px;
  border: 0px;
  margin-left: 8px;
  /* margin-right: 8px; */
}
.inputStyle:focus:focus {
  outline: none;
}

.outline-confirm-pin {
  border: 1px solid #f4465e;
  background: #ffeded;
}

.scrollbar-none::-webkit-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  width: 0px; /* Firefox */
}

.btn-triangle-body-red {
  width: "100%";
  height: 35;
  background-color: #dc3545;
  color: white;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-triangle-red {
  width: 0;
  height: 0;
  border-bottom: 35px solid #dc3545;
  border-left: 20px solid transparent;
}
.btn-triangle-body-green {
  width: "100%";
  height: 35;
  background-color: #198754;
  color: white;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-triangle-green {
  width: 0;
  height: 0;
  border-top: 35px solid #198754;
  border-right: 20px solid transparent;
}

.btn-triangle-body-light-grey {
  width: "100%";
  height: 35;
  background-color: #e9ecef;
  color: grey;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-triangle-left-light-grey {
  width: 0;
  height: 0;
  border-top: 35px solid #e9ecef;
  border-right: 20px solid transparent;
}
.btn-triangle-right-light-grey {
  width: 0;
  height: 0;
  border-bottom: 35px solid #e9ecef;
  border-left: 20px solid transparent;
}

.btn-triangle-body-dark-grey {
  width: "100%";
  height: 35;
  background-color: #282a32;
  color: grey;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-triangle-left-dark-grey {
  width: 0;
  height: 0;
  border-top: 35px solid #282a32;
  border-right: 20px solid transparent;
}
.btn-triangle-right-dark-grey {
  width: 0;
  height: 0;
  border-bottom: 35px solid #282a32;
  border-left: 20px solid transparent;
}

/* remove arrow at input number */
.remove-arrow1::-webkit-outer-spin-button,
.remove-arrow1::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* .remove-arrow2[type=number] {
  -moz-appearance: textfield;
} */

.accordion-button:focus {
  box-shadow: none !important;
  background-color: none;
}
